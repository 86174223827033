.ag-theme-balham {
  font-family: 'Telegraf', 'IBM Plex Sans' !important;
}

.ag-theme-deepcell {
  --ag-grid-size: 20px;
  --ag-foreground-color: #172623;
  --ag-background-color: #fff;
  --ag-header-foreground-color: #5f55d1;
  --ag-header-background-color: #e3fcee;
  --ag-header-column-resize-handle-color: #fff;
  --ag-balham-active-color: #5f55d1;
  --ag-border-color: #c4c6fc;
  --ag-input-border-color: #8b8ff1;
}

.lock-pinned {
  background-color: #e3fcee;
  color: #5f55d1;
}

.ag-value-bold {
  font-weight: bold;
}

.no-root-padding .ag-root-wrapper-body {
  padding: 0;
}

.ag-borderless-header .ag-header {
  border: 0;
}

/* Runs Details Theme*/

.ag-theme-v3 {
  --ag-font-family: 'Telegraf', 'IBM Plex Sans';
  --ag-font-size: '12px';
  font-size: 12px;
}

.ag-theme-v3 .ag-cell {
  padding: 0 10px;
  box-sizing: border-box;
  display: inline-block;
  white-space: nowrap;
}

.ag-theme-v3 .ag-header-cell {
  padding: 0 10px;
}

.ag-theme-v3 .ag-cell-value {
  display: flex;
  place-items: center;
}

.ag-theme-v3 .ag-cell-value .ag-react-container {
  width: 100%;
}

.ag-theme-v3 .ag-header {
  font-size: 12px;
  padding: 0 14px;
  border-bottom: 1px solid #dedede;
  white-space: normal;
}

.ag-theme-v3 .ag-body-viewport {
  padding: 10px 14px;
  font-size: 12px;
  height: auto;
}

.ag-theme-v3 .ag-body-horizontal-scroll {
  display: none;
}

.ag-theme-v3-runs-table .ag-body-horizontal-scroll-viewport {
  display: none;
}

.ag-theme-v3 .ag-center-cols-clipper {
  min-height: 0;
  height: unset !important;
}

/* Runs Table Theme */

.ag-theme-v3-runs-table {
  --ag-font-family: 'Telegraf', 'IBM Plex Sans';
  font-size: 14px;
}

.ag-theme-v3-runs-table .ag-root {
  padding-left: 16px;
}

.ag-theme-v3-runs-table .ag-header {
  border-radius: 4px;
  background: #e3fcee;
  margin: 0px;
}

.ag-theme-v3-runs-table .ag-header-cell {
  padding: 0;
  box-sizing: border-box;
  color: #172623;
  font-weight: bold;
}
.ag-theme-v3-runs-table .ag-cell {
  display: flex;
}

.ag-theme-v3-runs-table .ag-right-aligned-cell {
  justify-content: flex-end;
  padding-right: 30px !important;
}

.ag-theme-v3-runs-table .ag-cell-value,
.ag-theme-v3-runs-table .ag-cell {
  place-items: center;
  border-top: 1px solid #ededed;
  border-bottom: 1px solid #ededed;
}
.ag-theme-v3-runs-table .ag-pinned-left-cols-container .ag-cell:first-child {
  border-left: 1px solid #ededed;
}
.ag-theme-v3-runs-table .ag-center-cols-clipper .ag-cell-value:last-child {
  border-right: 1px solid #ededed;
}
.ag-theme-v3-runs-table .ag-cell-value .ag-react-container {
  width: 100%;
}

.ag-theme-v3-runs-table .ag-cell {
  padding: 0;
}

.ag-theme-v3-runs-table ::-webkit-scrollbar {
  width: 0px;
}

.ag-theme-v3-runs-table .ag-row {
  height: 50px;
  box-sizing: border-box;
  padding: 10px 0;
}

.ag-theme-v3-runs-table .ag-center-cols-container {
  width: calc(100% - 20px) !important;
  padding: 0px 0px;
}

/* Cell Browsing Theme */

.ag-theme-v3-browsing-table {
  --ag-font-family: 'Telegraf', 'IBM Plex Sans';
  font-size: 14px;
}

.ag-theme-v3-browsing-table .ag-center-cols-container {
  width: calc(100% - 20px) !important;
  padding: 0px 10px;
}

.ag-theme-v3-browsing-table .ag-body-horizontal-scroll {
  display: none;
}

.ag-theme-v3-browsing-table .ag-root {
  padding-left: 24px;
}

.ag-theme-v3-browsing-table .ag-header {
  border-radius: 4px;
  background: #e3fcee;
  margin: 0 10px;
}

.ag-theme-v3-browsing-table .ag-cell-value {
  display: flex;
  place-items: center;
  text-overflow: ellipsis;
  overflow: hidden;
}

.ag-theme-v3-browsing-table .ag-header-cell {
  padding: 0 15px;
  box-sizing: border-box;
  color: #172623;
  font-weight: bold;
}

.ag-theme-v3-browsing-table .ag-root-wrapper-body {
  padding: 0;
  padding-top: 24px;
}

.ag-theme-v3-browsing-table .ag-cell {
  padding: 0 15px;
}

.ag-theme-v3-browsing-table .ag-body-viewport {
  overflow: hidden;
}

/* .ag-theme-v3-browsing-table .ag-body-viewport.ag-layout-normal {
 overflow-y: auto;
} */

.ag-theme-v3-browsing-table .ag-row {
  height: 50px;
  box-sizing: border-box;
  padding: 10px 0;
}

/* .ag-theme-v3-browsing-table .ag-body-horizontal-scroll-viewport {
  display: none;
}

.ag-theme-v3-browsing-table ::-webkit-scrollbar {
  width: 0px;
} */

/* Cell Vis Table Theme  */

.ag-theme-v3-cell-vis-table {
  --ag-font-family: 'Telegraf', 'IBM Plex Sans';
  font-size: 14px;
}

.ag-theme-v3-cell-vis-table .ag-center-cols-container {
  width: calc(100% - 20px) !important;
  padding: 0px 10px;
}

.ag-theme-v3-cell-vis-table .ag-body-horizontal-scroll {
  display: none;
}

.ag-theme-v3-cell-vis-table .ag-header {
  border-radius: 4px;
  background: #e3fcee;
  margin: 0 10px;
}

.ag-theme-v3-cell-vis-table .ag-cell-value {
  display: flex;
  place-items: center;
  text-overflow: ellipsis;
  overflow: hidden;
}

.ag-theme-v3-cell-vis-table .ag-header-cell {
  padding: 0 15px;
  box-sizing: border-box;
  color: #172623;
  font-weight: bold;
}

.ag-theme-v3-cell-vis-table .ag-root-wrapper-body {
  padding: 0;
  padding-top: 24px;
}

.ag-theme-v3-cell-vis-table .ag-cell {
  box-sizing: border-box;

  padding: 0 15px;
}

.ag-theme-v3-cell-vis-table .ag-body-viewport {
  overflow: hidden;

}

.ag-theme-v3-cell-vis-table .ag-row {
  height: 50px;
  box-sizing: border-box;
  padding: 10px 0;
}

.ag-theme-v3-cell-vis-table .ag-body-horizontal-scroll-viewport {
  display: none;
}

.ag-theme-v3-cell-vis-table ::-webkit-scrollbar {
  width: 0px;
} 

.ag-theme-v3-cell-vis-table .ag-pinned-left-cols-container .ag-cell:first-child {
  border-left: 1px solid #EDEDED;
}

.ag-theme-v3-cell-vis-table .ag-center-cols-clipper .ag-cell-value:last-child {
  border-right: 1px solid #EDEDED;
}

.ag-theme-v3-cell-vis-table .ag-root {
  padding-left: 16px;
}

.ag-theme-v3-cell-vis-table .ag-header {
  margin: 0px;
}

.ag-theme-v3-cell-vis-table .ag-center-cols-container {
  width: calc(100% - 20px) !important;
  padding: 0px 0px;
}

.ag-theme-v3-cell-vis-table .ag-cell-value, .ag-theme-v3-cell-vis-table .ag-cell {
  place-items: center;
  border-top: 1px solid #EDEDED;
  border-bottom: 1px solid #EDEDED;
}

/* Context Menu Theme */

.context-menu-ag-grid .ag-popup-child:not(.ag-tooltip-custom) {
  box-shadow: 5px 5px 10px #0000004d;
}

.context-menu-ag-grid .ag-menu {
  background-color: white;
  border: solid 1px #bdc3c7;
  border-radius: 2px;
  box-shadow: none;
  padding: 4px;
  padding: 0;
}

.context-menu-ag-grid .ag-menu-list {
  padding: 4px 0;
}

.context-menu-ag-grid .ag-menu-option {
  padding: 4px;
}

.context-menu-ag-grid .ag-menu-option-disabled {
  opacity: 0.5;
}

.context-menu-ag-grid .ag-menu-option-part {
  font-size: 12px;
}

.context-menu-ag-grid .ag-menu-option-text,
.context-menu-ag-grid .ag-menu-option-shortcut {
  padding: 6px 8px;
}

.context-menu-ag-grid .ag-menu-option-active {
  background: #ecf0f1;
}
